<template>
    <div class="page-template">
        <div class="operate">
            <slot name="head">
                <div class="left"></div>
                <div class="right">
                    <el-button el-button type="primary" @click="rightConfig.handler">
                        <i class="el-icon-plus" style="margin-right: 5px"></i>
                        {{ rightConfig.text }}
                    </el-button>
                </div>
            </slot>
        </div>
        <div class="table">
            <el-scrollbar style="height: 100%;">
                <TableList :tableColumnConfig="tableConfig" :tableData="tableData" :rowKey="tableRowKey">
                    <template #default="{ item }">
                        <slot name="tableOperate" :item="item"></slot>
                    </template>
                </TableList>
            </el-scrollbar>
        </div>
        <div class="paging-box">
            <el-pagination
                :current-page.sync="pagingConfig.page"
                :page-size="pagingConfig.pageSize"
                layout="prev, pager, next, jumper"
                :total="totalCount"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
import TableList from "components/admin/common/TableList";
export default {
    name: "TableListPageTemplate",
    data() {
        return {};
    },
    components: {
        TableList,
    },
    props: {
        rightConfig: {
            type: Object,
            default: () => ({
                text: "",
                handler: () => {},
            }),
        },
        tableConfig: {
            type: Array,
            default: () => [],
        },
        tableData: {
            type: Array,
            default: () => [],
        },
        pagingConfig: {
            type: Object,
            default: () => {},
        },
        totalCount: {
            type: Number,
            default: 1,
        },
        tableRowKey: {
            type: String,
            default: "",
        },
    },
    methods: {},
};
</script>

<style scoped lang="scss">
.page-template {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    .operate {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .table {
        flex: 1;
        overflow: hidden;
    }
    .paging-box {
        padding: 20px 0;
        text-align: center;
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
</style>

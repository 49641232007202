<template>
    <div class="cate-template">
        <TableListPageTemplate
            v-if="getCateDataParams"
            v-bind="templateConfig"
            :pagingConfig.sync="getCateDataParams"
            :totalCount="totalCount"
        >
            <template #tableOperate="{ item }">
                <el-link v-if="item.level < maxLevel" type="primary" :underline="false" @click="addChildCate(item)"
                    >添加子分类</el-link
                >
                <el-link type="success" :underline="false" @click="edit(item)">编辑</el-link>
                <el-link type="danger" :underline="false" @click="deleteCateDialog(item)">删除</el-link>
            </template>
        </TableListPageTemplate>
        <Dialog ref="operateCate" :title="operateBtnTitle" :confirmHandler="confirmOperate" :cancelHandler="clearSendParams">
            <div class="content">
                <div class="input-item">
                    <div class="label">分类名称</div>
                    <el-input v-model="operateCateParams.name"></el-input>
                </div>
                <div v-if="type != 3 && actualIsShowModule" class="input-item">
                    <div class="label">模块选择</div>
                    <el-cascader
                        :props="actualOptions.props"
                        v-model="operateCateParams.module_id"
                        :options="actualOptions.options"
                    ></el-cascader>
                </div>
            </div>
        </Dialog>
        <Dialog ref="delete" title="删除分类" :confirmHandler="confirmDelete">
            <div class="delete">是否删除该分类，删除将无法恢复</div>
        </Dialog>
    </div>
</template>

<script>
import TableListPageTemplate from "components/admin/common/TableListPageTemplate";
import Dialog from "components/admin/common/Dialog";
export default {
    name: "CateTemplate",
    data() {
        return {
            tableData: [],
            getTheoryCateDataParams: {
                page: 1,
                limit: 10,
                type: this.type,
            },
            getActualCateDataParams: {
                paging: 1,
                page: 1,
                pageSize: 10,
            },
            operateCateParams: {
                name: "",
                type: this.type,
                id: "",
                pid: null, //添加顶级分类不传
                module_id: 0, //实操题
            },
            getCateDataParams: null,
            theoryParamsKey: ["name", "type", "id", "pid"],
            actualParamsKey: ["name", "id", "pid", "module_id"],
            deleteId: "",
            totalCount: 1,
            operateBtnTitle: "",
            actualIsShowModule: false,
        };
    },
    components: {
        TableListPageTemplate,
        Dialog,
    },
    props: {
        type: {
            type: [Number, String],
            default: "",
        },
        operateCate: {
            type: Function,
            default: () => {},
        },
        getData: {
            type: Function,
            default: () => {},
        },
        deleteCate: {
            type: Function,
            default: () => {},
        },
        actualOptions: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        templateConfig() {
            return {
                tableData: this.tableData,
                rightConfig: {
                    text: "添加分类",
                    handler: this.addCate,
                },
                tableConfig: [
                    { label: "分类名称", prop: "name" },
                    { label: "操作", width: 300, align: "center" },
                ],
                tableRowKey: "id",
            };
        },
        maxLevel() {
            return this.type == 3 ? 4 : 2;
        },
        packOperateParams() {
            const { operateCateParams, actualParamsKey, theoryParamsKey } = this;
            let keys = this.type == 3 ? theoryParamsKey : actualParamsKey;
            let params = {};
            keys.forEach((item) => {
                let value = operateCateParams[item];
                if (this.type != 3 && item == "module_id" && value && value.length > 0) {
                    value = value.slice(-1)[0];
                }
                value && this.$set(params, item, value);
            });
            return params;
        },
    },
    watch: {
        "getCateDataParams.page"() {
            this.getCateList();
        },
    },
    methods: {
        async getCateList() {
            let res = await this.getData(this.getCateDataParams);
            if (this.verifySend(res)) {
                let list = res.data.list;
                this.setLevel(list, 0);
                this.tableData = list;
                this.totalCount = res.data.total;
            }
        },
        setLevel(list, level) {
            list.forEach((item) => {
                item.level = level;
                if (item.children && item.children.length > 0) {
                    this.setLevel(item.children, level + 1);
                }
            });
        },
        verifySend(res) {
            let isSucceed = res.code == 200;
            !isSucceed && this.$message.error(res.msg);
            return isSucceed;
        },
        addCate() {
            this.operateBtnTitle = "添加分类";
            this.$refs.operateCate.open();
        },
        addChildCate(item) {
            this.operateBtnTitle = "添加二级分类";
            this.operateCateParams.pid = item.id;
            this.type != 3 && item.level == 1 && this.toggleActualModule(true);
            this.$refs.operateCate.open();
        },
        toggleActualModule(state) {
            this.actualIsShowModule = state;
        },
        edit(item) {
            this.operateBtnTitle = item.pid ? "编辑二级分类" : "编辑分类";
            this.setSendParams(item);
            this.type != 3 && item.level == 2 && this.toggleActualModule(true);
            this.$refs.operateCate.open();
        },
        async confirmOperate() {
            let res = await this.operateCate(this.packOperateParams);
            if (this.verifySend(res)) {
                this.clearSendParams();
                this.getCateList();
                this.$message.success(res.msg);
                this.$refs.operateCate.close();
            }
        },
        setSendParams(item) {
            let { operateCateParams } = this;
            operateCateParams.name = item ? item.name : "";
            operateCateParams.id = item ? item.id : "";
            operateCateParams.pid = item ? item.pid : null;
            operateCateParams.module_id = item ? item.module_id : 0;
        },
        clearSendParams() {
            this.setSendParams();
            this.toggleActualModule(false);
        },

        deleteCateDialog(item) {
            this.deleteId = item.id;
            this.$refs.delete.open();
        },
        async confirmDelete() {
            let res = await this.deleteCate({ id: this.deleteId });
            this.deleteId = "";
            if (this.verifySend(res)) {
                this.$message.success(res.msg);
                this.getCateList();
                this.$refs.delete.close();
            }
        },
    },
    mounted() {
        this.getCateDataParams = this.type == 3 ? this.getTheoryCateDataParams : this.getActualCateDataParams;
        this.getCateList();
    },
};
</script>

<style scoped lang="scss">
.cate-template {
    height: 100%;
    color: #333;
    .content {
        font-size: 16px;
        margin-bottom: 15px;
        .input-item {
            margin-bottom: 12px;
            text-align: start;
            .label {
                text-align: start;
                margin-bottom: 12px;
            }
            ::v-deep .el-cascader {
                width: 100%;
            }
        }
    }
    .delete {
        margin: 50px 0;
    }
}
</style>
